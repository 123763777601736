body {
  overflow-x: hidden;
}
.App {
  text-align: center;
  background-color: #fcf8f5;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  /*min-height: 10vh;*/
  display: flex;
  padding: 0 2%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-header {
  width: 100%;
  max-width: 1000px;
  position: absolute;
  top: 20px;
  left: 40px;
  text-align: left;
  z-index: 99;
  font-size: 1em;
  font-weight: bold;
  /*color: #312c28;*/
  color: #101c36;
}

.App-link {
  color: #61dafb;
}

.nowrap {
  white-space: nowrap;
}

.product {
  padding: 80px 3% 0px 3%;
  max-width: 800px;
}
.product > img {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

footer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: end;
  font-weight: bold;
  font-size: 0.7em;
  color: #7d7168;
  padding-bottom: 40px;
}
footer a {
  color: #7d7168;
  text-decoration: none;
}
